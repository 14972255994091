<template>
  <div class="deposit_edit world">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="paddB10">
      <el-breadcrumb-item :to="{ path: '/enterprise/centre/lanzhou/deposit' }" class="bs_text_oo">
        返回上一级<span> | </span>残保金审核
      </el-breadcrumb-item>
      <el-breadcrumb-item class="color66">新增</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content backgroundWhite">
      <h1 class="font18 bold">按比例安置残疾人就业保障金年审申请</h1>
      <ul class="tab flex">
        <li v-for="(n,i) in tabs" :key="i" :class="i === 0?'active':''">{{ n }}</li>
      </ul>
      <div class="container">
        <div class="tip">
          <p> 说明：1、企业名称、营业执照等信息为您注册时使用的信息，如信息错误请在用户中心—企业信息中修改。</p>
          <p>2、所有上传材料需加盖本单位公章</p>
        </div>
        <div class="form">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-form-inline">
            <el-form-item label="单位全称" prop="companyName">
              <el-input v-model="ruleForm.companyName" class="w430" disabled></el-input>
            </el-form-item>
            <el-form-item label="营业执照" prop="licenseUrl">
              <img :src="ruleForm.licenseUrl?ruleForm.licenseUrl:require('@/static/public/default1.png')" alt=""
                   class="block_inline photo">
            </el-form-item>
            <el-form-item label="社会信用代码" prop="socialCredit">
              <el-input v-model="ruleForm.socialCredit" class=" w430" disabled></el-input>
            </el-form-item>
            <el-form-item label="法人代表" prop="corporateRepresentative">
              <el-input v-model="ruleForm.corporateRepresentative" class="w430" placeholder="请输入法人代表姓名"></el-input>
            </el-form-item>
            <el-form-item label="注册地址">
              <el-input v-model="area" class="w430"></el-input>
            </el-form-item>
            <el-form-item label="电话号码" prop="telphone">
              <el-input v-model="ruleForm.telphone" class="w430" placeholder="请输入电话号码"></el-input>
            </el-form-item>
            <el-form-item label="单位性质" prop="qyxz">
              <el-radio-group v-model="ruleForm.qyxz" class="marL10 lineH40 transformY10">
                <el-radio v-for="(item,index) in comTypeList" :label="item.value" :key="index">{{ item.text }}
                </el-radio>

              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="buttons_add">
        <el-button type="primary"  class="w170" @click="submit" :loading="loading"> 下一步
        </el-button>
        <div class="tips">
          点击保存，信息会存为草稿，提交前均可编辑，确保信息无误后再提交。
        </div>
      </div>

    </div>
  </div>
</template>
<script>

export default {
  name: "edit",
  data() {
    return {
      tabs: ['单位信息', '安置残疾人就业信息', '全员工资表', '就业统计表', '社保缴费证明', '医保缴费证明'],
      ruleForm: {},
      area: '',
      rules: {
        corporateRepresentative: [
          {required: true, message: '请填写法人代表', trigger: 'blur'},
        ],
        telphone: [
          {required: true, message: '请填写联系电话', trigger: 'change'}
        ],
        qyxz: [
          {required: true, message: '请选择单位性质', trigger: 'change'},
        ],
      },
      comTypeList: [
        {
          value: '1',
          text: '行政'
        },
        {
          value: '2',
          text: '事业'
        },
        {
          value: '3',
          text: '企业'
        },
        {
          value: '4',
          text: '其他'
        },
      ],
      loading:false,
    }
  },
  methods: {
    //保存
    submit(){
      this.$refs.ruleForm.validate((validate) =>{
        if (validate){
          this.loading = true;
          this.$api.lanzhouUpdateComApi(this.ruleForm).then(res =>{
            if(res.data.success){
              this.$message.success('保存成功！');
              setTimeout(() =>{
                this.loading = false;
                this.$router.push('/enterprise/centre/lanzhou/deposit/nextEdit');
              },1000)
            }else{
              this.loading = false;
            }
          })
        }
      })
    },
    getComInfo: function () {
      this.$api.lanzhouGetComInfoApi().then(response => {
        this.ruleForm = response.data.data;
        if (!this.ruleForm.qyxz) {
          this.ruleForm.qyxz = '1';
        }
        this.area = this.ruleForm.registerProvinced + this.ruleForm.registerCity + (this.ruleForm.registerThreecity ? this.ruleForm.registerThreecity : '') + this.ruleForm.registerDetail;

      });
    },
  },
  created: function () {
    this.getComInfo();
  },
}
</script>

<style scoped lang="less">
@import "~assets/lanzhou/enterprise.less";
@import "~assets/lanzhou/deposit.less";
</style>
